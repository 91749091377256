body {
  margin: 0;
  font-family: 'Lato', sans-serif;
  font-size: 20px;
  line-height: 1.6;
}

p {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  margin-block-start: 1em;
  margin-block-end: 1.5em;
}

.mdx.mdx {
  line-height: 1.6;
}

h1 {
  font-size: 32px;
  margin: 0;
  line-height: 1.25;
}

h4 {
  margin-bottom: 3em;
}

a {
  color: inherit;
  text-decoration: none;
}

a:hover {
  color: #4570fa;
}

.mdx a {
  text-decoration: underline;
  color: #4570fa;
  cursor: pointer;
}

.mdx img {
  width: 100%;
  margin: 12px 0;
}

@media only screen and (max-width: 599px) {
  body {
    font-size: 18px;
  }
}
